import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('header', {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    padding: '3rem calc(2 * $colWidth)',
    zIndex: 9,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '.not-found &': {
        backgroundColor: '$background',
    },
    '@mdDown': {
        backgroundColor: '$background',
        padding: '1.5rem calc(2 * $colWidth)',
    },
})

export const MenuWrapper = styled('div', {
    display: 'flex',
    gap: '2rem',
})

export const LogoWrapper = styled('div', {
    width: '150px',
    img: {
        width: '100%',
        height: 'auto',
    },

    '@md': {
        width: 'calc(2.5 * $colWidth)',
    },
    mixBlendMode: 'difference',
})

export const MenuItem = styled('a', {
    fontSize: '1.15rem',
    color: '#fff',
    '.not-found &': {
        color: '$primary',
    },
    '@xl': {
        fontSize: '1.4rem',
    },
})

export const Divider = styled('span', {
    display: 'inline-block',
    verticalAlign: 'middle',
    height: '1.4em',
    width: '1px',
    backgroundColor: '#fff',
    '.not-found &': {
        backgroundColor: '$primary',
    },
})
