import Padder from 'src/components/_layout/Padder/Padder'
import ImageReveal from 'src/components/atoms/ImageReveal/ImageReveal'
import RichText from 'src/components/atoms/RichText/RichText'
import SlashLink from 'src/components/atoms/SlashLink/SlashLink'
import Text from 'src/components/atoms/Text/Text'
import { CmsImage, CmsLink } from 'src/types'
import {
    Features,
    InfoWrapper,
    ItemImage,
    ItemRow,
    Price,
    Title,
} from './RoomsListing.style' // Adjust path
import Image from 'src/components/atoms/Image/Image'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import { Link } from 'react-router-dom'
import useLabel from 'src/hooks/useLabel'

export type RoomItemProps = {
    title: string
    image: CmsImage
    link: CmsLink
    properties: {
        location: string
        code: string
        pricePerNight: string
        notes?: string
    } & Record<string, string>
}

export default function RoomItem(props: RoomItemProps) {
    const {
        image,
        title,
        link,
        properties: { code, location, pricePerNight, notes, ...restProperties },
    } = props
    const label = useLabel()

    return (
        <ItemRow>
            <Revealer>
                <ItemImage as={Link} to={link.url}>
                    <Image src={image.url} alt={title} />
                </ItemImage>
            </Revealer>

            <Padder top={{ '@initial': 'xs', '@md': 'md' }}>
                <Title>
                    <Text textVariant="title" css={{ fontWeight: '$bold' }}>
                        {code}
                    </Text>
                    <Text textVariant="title">{title}</Text>
                    <Text textVariant="title">{location}</Text>
                </Title>
                <InfoWrapper>
                    <Features>
                        {Object.keys(restProperties).map((key) => (
                            <Text textVariant="base" key={key}>
                                {label(key)}: {restProperties[key]}
                            </Text>
                        ))}
                    </Features>
                    <Price as={Padder} top={{ '@initial': 'xxs', '@md': 'md' }}>
                        <div>
                            <Text
                                textVariant="base2"
                                dangerouslySetInnerHTML={{
                                    __html: label('price_per_night'),
                                }}
                            />
                            <Text
                                textVariant="base2"
                                css={{ fontWeight: '$bold' }}
                            >
                                {pricePerNight}
                            </Text>
                        </div>
                        <div>
                            {notes && (
                                <Text textVariant="base">
                                    <RichText htmlString={notes} />
                                </Text>
                            )}
                        </div>
                    </Price>
                    <div>
                        <SlashLink link={link} />
                    </div>
                </InfoWrapper>
            </Padder>
        </ItemRow>
    )
}
