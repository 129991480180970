import { styled } from 'src/styles/stitches.config'

const FlexWrapper = styled('div', {
    display: 'flex',
    variants: {
        direction: {
            column: {
                flexDirection: 'column',
            },
            row: {
                flexDirection: 'row',
            },
        },
        justify: {
            between: {
                justifyContent: 'space-between',
            },
            center: {
                justifyContent: 'center',
            },
            start: {
                justifyContent: 'flex-start',
            },
            end: {
                justifyContent: 'flex-end',
            },
        },
        align: {
            start: {
                alignItems: 'flex-start',
            },
            end: {
                alignItems: 'flex-end',
            },
            center: {
                alignItems: 'center',
            },
        },
        fitContent: {
            true: {
                '>div': {
                    width: '100%',
                },
            },
        },
    },
})

export default FlexWrapper
