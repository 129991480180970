import * as Styles from './SlashLink.style'
import Text from '../Text/Text'
import { CmsLink } from 'src/types'
import { Link } from 'react-router-dom'
import Button from '../Button/Button'

type SlashLinkProps = {
    link: CmsLink
}

export default function SlashLink({ link }: SlashLinkProps) {
    const href = { [link.target === '_blank' ? 'href' : 'to']: link.url }
    return (
        <Button
            as={link.target === '_blank' ? 'a' : Link}
            {...href}
            target={link.target}
            aria-label={link.title}
        >
            {link.title}
        </Button>
    )
}
