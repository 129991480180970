import { styled } from 'src/styles/stitches.config'

const RoundButton = styled('button', {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
    height: '60px',
    border: 'solid 1px $black',
    borderRadius: '50%',
    fontSize: '1.5rem',
    color: '$black',

    '@hover': {
        '&::before': {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '0%',
            height: '0%',
            margin: 'auto',
            backgroundColor: '$white',
            mixBlendMode: 'difference',
            borderRadius: '50%',
            transition: 'all .35s ease-out',
            content: '',
        },

        '&:hover::before': {
            width: '80%',
            height: '80%',
        },
    },

    '&.swiper-button-disabled': {
        opacity: 0,
        visibility: 'hidden',
    },

    variants: {
        tint: {
            dark: {
                borderColor: '$black',
                color: '$black',
                transition: 'color .2s linear',
                '@hover': {
                    '&:hover': {
                        color: '$white',
                    },
                },
            },
            light: {
                borderColor: '$white',
                color: '$white',
            },
        },
        nopointer: {
            true: {
                pointerEvents: 'none',
            },
        },
        small: {
            true: {
                '@sm': {
                    width: '95px',
                    height: '95px',
                    fontSize: '1.5rem',
                },
            },
        },
    },
})

export default RoundButton
