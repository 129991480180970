import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from 'src/store'

type LayoutType = {
    isLoading: boolean
}

const initialState: LayoutType = {
    isLoading: false,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => ({
            ...state,
            isLoading: action.payload,
        }),
    },
})

export const { setIsLoading } = layoutSlice.actions
export const selectIsLoading = (state: RootState) => state.layout.isLoading
export default layoutSlice.reducer
