import { CmsImage, CmsLink } from 'src/types'
import * as Styles from './PreFooter.style'
import Image from 'src/components/atoms/Image/Image'
import RichText from 'src/components/atoms/RichText/RichText'
import SlashLink from 'src/components/atoms/SlashLink/SlashLink'
import Padder from 'src/components/_layout/Padder/Padder'

type PreFooterProps = {
    Image: CmsImage
    logo: CmsImage
    description: string
    link: CmsLink
}

const PreFooter: React.FC<PreFooterProps> = ({
    Image: image,
    logo,
    description,
    link,
}) => {
    return (
        <Styles.Wrapper>
            <Styles.ImageWrapper>
                <Image src={image.url} alt={image.alt} />
            </Styles.ImageWrapper>
            <Styles.InfoWrapper>
                <Styles.LogoWrapper>
                    <Image src={logo.url} alt={logo.alt} />
                </Styles.LogoWrapper>
                <Padder top={{ '@initial': 'sm', '@md': 'lg' }}>
                    <RichText htmlString={description} textVariant="base" />
                    <Padder
                        css={{
                            '@md': {
                                textAlign: 'right',
                                paddingRight: 'calc(2 * $colWidth)',
                            },
                        }}
                    >
                        <SlashLink link={link} />
                    </Padder>
                </Padder>
            </Styles.InfoWrapper>
        </Styles.Wrapper>
    )
}

export default PreFooter
