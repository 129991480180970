import { useState } from 'react'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Text from 'src/components/atoms/Text/Text'
import * as Styles from './Tabs.style'
type MobileItemProps = {
    title: string
    content?: JSX.Element
    defaultOpen?: boolean
}

export default function MobileItem({
    title,
    content,
    defaultOpen,
}: MobileItemProps) {
    const [isOpen, setIsOpen] = useState(!!defaultOpen)
    return (
        <div>
            <FlexWrapper
                as="button"
                justify="between"
                onClick={() => {
                    setIsOpen((prevState) => !prevState)
                }}
            >
                <Text textVariant="heading3">{title}</Text>
                <Text textVariant="heading3">{isOpen ? '-' : '+'}</Text>
            </FlexWrapper>
            <Styles.MobileContent open={isOpen}>
                <div style={{ paddingTop: '20px' }}>{content}</div>
            </Styles.MobileContent>
        </div>
    )
}
