import { createSelector } from 'reselect'
import { RootState } from 'src/store'

export const getLayers = (state: RootState) => state.layers

export const getLayerById = createSelector(
    getLayers,
    (_: RootState, layerId: string) => layerId,
    (layers, layerId) => layers.find(({ id }) => id === layerId)
)
