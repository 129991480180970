import { styled } from 'src/styles/stitches.config'

export const StyledWhatsapp = styled('div', {
    position: 'fixed',
    bottom: '2rem',
    right: '2rem',
    zIndex: 2,
    fontSize: '2.5rem',
    color: '$primary',
})
