import { styled } from 'src/styles/stitches.config'

const Submit = styled('button', {
    display: 'inline-block',
    position: 'relative',
    fontWeight: '$bold',
    fontSize: '2rem',
    width: 'auto',
    padding: '0 10px',
    '&::before': {
        position: 'absolute',
        top: '100%',
        left: 0,
        width: '100%',
        height: '2px',
        backgroundColor: '$black',
        content: '',
    },
})

export default Submit
