import { styled } from 'src/styles/stitches.config'

export const StyledAccomodationForm = styled('form', {
    strong: {
        fontWeight: '$bold',
    },
    '@sm': {
        width: '75%',
    },
})

export const SubmitWrapper = styled('div', {
    marginTop: '$xs',
    '@md': {
        textAlign: 'right',
        marginTop: 0,
    },
})

export const FormRow = styled('div', {
    '@lg': {
        '>div': {
            width: '30%',
        },
    },
    '@lgDown': {
        '>div:nth-child(2)': {
            marginBottom: '$xs',
            marginTop: '$xs',
        },
    },
})

export const selectStyles = {
    container: (provided: any) => ({
        ...provided,
        width: '100%',
    }),
    control: (provided: any) => ({
        ...provided,
        backgroundColor: 'var(--colors-background)',
        border: 'none',
        borderBottom: '1px solid var(--colors-black)',
        borderRadius: '0',
        boxShadow: 'none!important',
        height: '50px',
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem',
        width: '100%',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: 'var(--colors-black)',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: '#000',
        backgroundColor: state.isSelected
            ? '#ddd'
            : state.isFocused
            ? '#eee'
            : '#fff',
    }),
}
