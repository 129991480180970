import { motion } from 'framer-motion'

import { StyledLoader } from './Loader.style'

const Loader: React.VFC = (props) => {
    return (
        <StyledLoader
            key="loader"
            as={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        />
    )
}

export default Loader
