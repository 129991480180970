import { useResponsive } from 'ahooks'
import { format, parse } from 'date-fns'
import { useCallback, useMemo } from 'react'
import 'react-day-picker/dist/style.css'
import { SubmitHandler, useForm } from 'react-hook-form'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Padder from 'src/components/_layout/Padder/Padder'
import Button from 'src/components/atoms/Button/Button'
import Input from 'src/components/atoms/Input/Input'
import Text from 'src/components/atoms/Text/Text'
import useLabel from 'src/hooks/useLabel'
import './BookingForm.css'
import {
    DateWrapper,
    StyledBookingForm,
    SubmitWrapper,
} from './BookingForm.style'

type Inputs = {
    dates: string
    adults: number
    children?: number
}

const BookingForm: React.FC = () => {
    const label = useLabel()
    const responsiveInfo = useResponsive()

    const column = useMemo(() => !responsiveInfo.md, [responsiveInfo])
    const { register, handleSubmit, setValue } = useForm<Inputs>()

    const onFormSubmit: SubmitHandler<Inputs> = useCallback((data) => {
        const dates = data.dates
            .split(' - ')
            .map((d) => format(parse(d, 'dd/MM/yyyy', new Date()), 'yyyyMMdd'))
        // window.location.href = `${booking.url}?${stringParams}`
        window.open(
            `https://booking.mainapps.com/#/step1/${dates[0]}/${dates[1]}/${
                data.adults
            }/${data.children || '0'}/?apikey=fMZ1Ge2tcu`,
            '_blank'
        )
    }, [])

    return (
        <StyledBookingForm onSubmit={handleSubmit(onFormSubmit)}>
            <Padder top="lg">
                <Text textVariant="heading1">{label('Book Now')}</Text>
                <FlexWrapper
                    as={Padder}
                    css={!column ? { height: '100%' } : undefined}
                    {...(column ? { top: 'sm' } : { left: 'sm' })}
                    justify={column ? 'start' : 'between'}
                    align={column ? 'start' : 'center'}
                    direction={column ? 'column' : 'row'}
                >
                    <DateWrapper>
                        <Text textVariant="base">{label('dates')}</Text>
                        <Input
                            type="date"
                            name="dates"
                            register={register}
                            setValue={setValue}
                        />
                    </DateWrapper>

                    <DateWrapper css={column ? { width: '100%' } : undefined}>
                        <Text textVariant="base">{label('adults')}</Text>
                        <Input
                            type="number"
                            name="adults"
                            value="2"
                            register={register}
                            setValue={setValue}
                        />
                    </DateWrapper>
                    <DateWrapper css={column ? { width: '100%' } : undefined}>
                        <Text textVariant="base">{label('children')}</Text>
                        <Input
                            type="number"
                            name="children"
                            value="2"
                            register={register}
                            setValue={setValue}
                        />
                    </DateWrapper>
                    <DateWrapper>
                        <SubmitWrapper>
                            <Padder css={{ display: 'inline-block' }}>
                                <Button
                                    css={{
                                        color: '$background',
                                        width: 'auto',
                                    }}
                                >
                                    {label('Book now').toUpperCase()}
                                </Button>
                            </Padder>
                        </SubmitWrapper>
                    </DateWrapper>
                </FlexWrapper>
            </Padder>
        </StyledBookingForm>
    )
}

export default BookingForm
