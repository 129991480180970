import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 'calc(2 * $colWidth)',
    paddingRight: 'calc(2 * $colWidth)',
    '@md': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
        paddingRight: 20,
    },
})

export const ImageWrapper = styled('div', {
    width: 'calc(10 * $colWidth)',

    '& img': {
        maxWidth: 'calc(4 * $colWidth)',
    },
    '@mdDown': {
        display: 'none',
    },
})

export const InfoWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$sm',
    '@md': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 'calc(14 * $colWidth)',
        gap: '$colWidth',
    },
})

export const LogoWrapper = styled('div', {
    width: '25vw',
    '&+div': {
        width: '60vw',
    },
    '@md': {
        width: 'calc(3 * $colWidth)',
        '&+div': {
            width: 'calc(10 * $colWidth)',
        },
    },
})
