import { CmsImage } from 'src/types'
import * as Styles from './PageTitle.style'
import Image from 'src/components/atoms/Image/Image'
import Container from 'src/components/_layout/Container/Container'
import { Title } from 'src/components/atoms/Title/Title'
import { AnimatePresence } from 'framer-motion'
import Letters from 'src/components/atoms/Letters/Letters'

type PageTitleProps = {
    image: CmsImage
    title: string
}

const PageTitle: React.FC<PageTitleProps> = ({ image, title }) => {
    return (
        <Container>
            <Styles.Wrapper>
                <Image src={image.url} alt={image.alt} />
            </Styles.Wrapper>
            <Styles.TitleFooter>
                <Title>
                    <AnimatePresence exitBeforeEnter>
                        <Letters string={title} />
                    </AnimatePresence>
                </Title>
            </Styles.TitleFooter>
        </Container>
    )
}

export default PageTitle
