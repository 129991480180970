import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    position: 'relative',
    maxWidth: '100%',
    ratio: {
        w: 2,
        h: 1,
    },
    img: {
        objectFit: 'cover',
        objectPosition: 'center',
    },
    borderRadius: '6.25vw',
    overflow: 'hidden',
    '@md': {
        ratio: {
            w: 1886,
            h: 611,
        },
    },
})

export const TitleFooter = styled('div', {
    position: 'absolute',
    bottom: '3rem',
    paddingLeft: `calc(2 * $colWidth)`,
    paddingRight: `calc(2 * $colWidth)`,
    zIndex: 2,
    color: '#fff',
    width: '90%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@mdDown': {
        bottom: 'auto',
        top: '50%',
        paddingLeft: `0`,
        paddingRight: `0`,
        left: 0,
        right: 0,
        transform: 'translateY(-50%)',
        justifyContent: 'center',
    },
})
