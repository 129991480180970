import React from 'react'
import { StyledImage } from './Image.style'

type CustomImageProps = Omit<React.ImgHTMLAttributes<any>, 'id'> & {
    url?: string
    id?: number
}

const Image: React.FC<CustomImageProps> = ({ url, src, id, ...rest }) => {
    return <StyledImage {...rest} src={url ?? src} />
}

export default Image
