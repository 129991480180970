import { styled, theme } from 'src/styles/stitches.config'

const setSpace = (property: string, applyTo = '> *:not(:first-child)') => {
    return Object.fromEntries(
        Object.keys(theme.space).map((key) => [
            key,
            //@ts-ignore
            { [applyTo]: { [property]: theme.space[key] } },
        ])
    )
}

export default styled('div', {
    variants: {
        top: setSpace('paddingTop'),
        left: setSpace('paddingLeft'),
    },
})
