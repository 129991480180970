import { styled } from 'src/styles/stitches.config'

export const StyledLayerMenu = styled('div', {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 0,
    height: '100vh',
    backgroundColor: '$background',
    zIndex: 9,
    color: '$primary',
    '@mdDown': {
        overflow: 'scroll',
    },
})

export const MenuPanel = styled('div', {
    width: '100%',
    '@mdDown': {
        textAlign: 'center',
        marginTop: '$sm',
    },
    variants: {
        big: {
            true: {
                '@md': {
                    width: '50%',
                    borderLeft: 'solid 1px $white',
                    borderRight: 'solid 1px $white',
                },
            },
        },
    },

    a: {
        position: 'relative',
        display: 'inline-block',
        '&::after': {
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            height: 1,
            width: 0,
            margin: 'auto',
            transition: 'width .25s ease-out',
            backgroundColor: '$white',
            content: '',
        },
        '@hover': {
            '&:hover::after': {
                width: '100%',
            },
        },
    },
})

export const SocialWrapper = styled('div', {
    width: '100%',
    marginTop: '$sm',
    textAlign: 'center',
})
