import { PropsWithChildren, useContext, useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import Scrollbar from 'react-smooth-scrollbar'
import { ScrollbarContext } from 'src/context/scrollbar'

import { StyledGlobalScroller } from './GlobalScroller.style'

const GlobalScroller: React.VFC<PropsWithChildren<any>> = ({ children }) => {
    const { scrollbar } = useContext(ScrollbarContext)
    const location = useLocation()
    const timeoutRef = useRef<any>()
    useEffect(() => {
        if (location.hash.length > 1) {
            if (timeoutRef.current) {
                global.clearTimeout(timeoutRef.current)
            }
            timeoutRef.current = setTimeout(() => {
                scrollbar.current.scrollbar.scrollTo(0, 0, 700)
            }, 2500)
        }
    }, [scrollbar, location])

    return (
        //@ts-ignore
        <StyledGlobalScroller as={Scrollbar} ref={scrollbar}>
            {children}
        </StyledGlobalScroller>
    )
}

export default GlobalScroller
