import { CmsImage, CmsLink } from 'src/types'
import * as Styles from './ImageLink.style'
import { Link } from 'react-router-dom'
import Image from 'src/components/atoms/Image/Image'

type ImageLinkProps = {
    image: CmsImage
    link: CmsLink
}

const ImageLink: React.FC<ImageLinkProps> = ({ image, link }) => {
    const href = { [link.target === '_blank' ? 'href' : 'to']: link.url }

    return (
        <Styles.Wrapper
            as={link.target === '_blank' ? 'a' : Link}
            {...href}
            target={link.target}
            aria-label={link.title}
        >
            <Image src={image.url} {...image} />
        </Styles.Wrapper>
    )
}

export default ImageLink
