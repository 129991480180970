import { styled } from 'src/styles/stitches.config'

const Wrapper = styled('div', {
    width: '100%',
    paddingLeft: `calc(2 * $colWidth)`,
    paddingRight: `calc(2 * $colWidth)`,
})

const ItemRow = styled('div', {
    '@md': {
        display: 'flex',
        gap: '7.25rem',
    },
})

const ItemImage = styled('div', {
    position: 'relative',
    overflow: 'hidden',
    width: `100%`,

    borderRadius: '5vw',
    ratio: { w: 517, h: 353 },
    '@md': {
        width: `calc(7 * $colWidth)`,
    },
    '&  > img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
})

const InfoWrapper = styled('div', {
    display: 'flex',
    gap: '$xs',
    flexWrap: 'wrap',
    flexDirection: 'column',

    '@md': {
        alignItems: 'flex-end',
        flexDirection: 'row',
        gap: '$sm',
    },
    '& > div:first-child': {
        '@md': {
            width: '33%',
        },
    },

    '& > div:nth-child(2)': {
        width: '60%',
        '@md': {
            width: '33%',
        },
    },

    '& > div:last-child': {
        display: 'inline-flex',
        alignItems: 'center',
        width: '30%',
        '@md': {
            width: '20%',
        },
    },
})

const Bar = styled('div', {}) // No styles provided
const Title = styled('div', {
    paddingTop: '$sm',
})
const Features = styled('div', {
    lineHeight: 1.2,
    '>div': {
        '&::first-letter': {
            textTransform: 'uppercase',
        },
    },
})

export const Price = styled('div', {})

export { Wrapper, ItemRow, ItemImage, InfoWrapper, Bar, Title, Features }
