import Padder from 'src/components/_layout/Padder/Padder'
import RoomItem, { RoomItemProps } from './RoomItem'
import * as Styles from './RoomsListing.style'
import Text from '../../atoms/Text/Text'
import RichText from 'src/components/atoms/RichText/RichText'
import { useMemo } from 'react'

type RoomsListingProps = {
    title: string
    roomsListing?: RoomItemProps[]
    items?: RoomItemProps[]
}

export default function RoomsListing({
    title,
    roomsListing = [],
    items,
}: RoomsListingProps) {
    const rooms = useMemo(() => items || roomsListing, [items, roomsListing])
    return (
        <Styles.Wrapper>
            <Padder top="lg">
                <RichText textVariant="heading1" htmlString={title} />
                {rooms.map((itemProps, i) => (
                    <RoomItem key={i} {...itemProps} />
                ))}
            </Padder>
        </Styles.Wrapper>
    )
}
