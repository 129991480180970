import { RiWhatsappFill } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { getSettings } from 'src/selectors/settings'
import { StyledWhatsapp } from './Whatsapp.style'

const Whatsapp: React.FC = (props) => {
    const { telephone } = useSelector(getSettings)
    return (
        <StyledWhatsapp id="whatsapp">
            <a href={`https://wa.me/${telephone}`} target="blank">
                <RiWhatsappFill />
            </a>
        </StyledWhatsapp>
    )
}

export default Whatsapp
