import { breakpoints, styled } from 'src/styles/stitches.config'
import { fluidSize } from 'src/styles/styleUtils'

const Button = styled('button', {
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',
    width: 'auto',
    fontWeight: '$bold',
    lineHeight: '1.2',
    fontSize: '18px',
    paddingRight: '2rem',
    '@sm': {
        fontSize: fluidSize(18, 30, breakpoints.sm, breakpoints.xxl),
    },
    '@xxl': {
        fontSize: '30px',
    },
    '&::before': {
        fontWeight: '$normal',
        content: '/',
        fontSize: '50px',
        paddingRight: '2rem',
        '@sm': {
            fontSize: fluidSize(50, 112, breakpoints.sm, breakpoints.xxl),
        },
        '@xxl': {
            fontSize: '112px',
        },
    },
    '&::after': {
        position: 'absolute',
        left: '100%',
        top: 0,
        fontWeight: '$normal',
        content: '/',
        fontSize: '7rem',
        paddingRight: '2rem',
        transform: 'translateX(-20px)',
        transition: 'all .4s ease-out',
        opacity: 0,
    },
    '@hover': {
        '&:hover::after': {
            transform: 'translateX(0px)',
            opacity: 1,
        },
    },
})

export default Button
