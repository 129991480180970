import { useDispatch, useSelector } from 'react-redux'
import * as Styles from './Header.style'
import { getSettings } from 'src/selectors/settings'
import { Link } from 'react-router-dom'
import Logo from 'src/components/atoms/Logo/Logo'
import { Fragment, useCallback, useMemo } from 'react'
import { useResponsive } from 'ahooks'
import Text from 'src/components/atoms/Text/Text'
import { RootState } from 'src/store'
import { getLayerById } from 'src/selectors/layers'
import { toggleLayer } from 'src/state/layers'
import RoundButton from 'src/components/atoms/RoundButton/RoundButton'
import { HiBars3 } from 'react-icons/hi2'
import Portal from 'src/components/helpers/Portal/Portal'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'

const Header: React.FC = (props) => {
    const responsiveInfo = useResponsive()
    const dispatch = useDispatch()
    const menuLayer = useSelector((state: RootState) =>
        getLayerById(state, 'mainMenu')
    )
    const { menu, logo, languages, translations } = useSelector(getSettings)
    const mainMenu = useMemo(() => menu[0] || { items: [] }, [menu])
    const toggleMenu = useCallback(() => {
        if (menuLayer) {
            dispatch(
                toggleLayer({
                    layerId: menuLayer.id,
                })
            )
        }
    }, [dispatch, menuLayer])

    const Wrapper = useMemo(() => {
        return responsiveInfo.md ? Fragment : Portal
    }, [responsiveInfo.md])

    const wrapperProps = useMemo(() => {
        return responsiveInfo.md ? {} : { domQuery: '#root' }
    }, [responsiveInfo.md])

    return (
        //@ts-ignore
        <Wrapper {...wrapperProps}>
            <Styles.Wrapper>
                <Styles.LogoWrapper>{!!logo && <Logo />}</Styles.LogoWrapper>
                <Styles.MenuWrapper>
                    {responsiveInfo.md ? (
                        <>
                            {mainMenu.items.map((item) => (
                                <Styles.MenuItem
                                    as={Link}
                                    to={item.url}
                                    key={item.ID}
                                >
                                    {item.title}
                                </Styles.MenuItem>
                            ))}
                            <FlexWrapper css={{ gap: '$xxs' }}>
                                {Object.keys(languages).map((key, index) => (
                                    <>
                                        <Styles.MenuItem
                                            as={'a'}
                                            href={`${
                                                translations &&
                                                translations[key]
                                                    ? translations[key]
                                                    : `/${languages[key].language_code}`
                                            }`}
                                            key={languages[key].id}
                                        >
                                            {languages[
                                                key
                                            ].language_code.toUpperCase()}
                                        </Styles.MenuItem>
                                        {index <
                                            Object.keys(languages).length -
                                                1 && <Styles.Divider />}
                                    </>
                                ))}
                            </FlexWrapper>
                        </>
                    ) : (
                        <div style={{ mixBlendMode: 'difference' }}>
                            <RoundButton
                                onClick={toggleMenu}
                                css={{
                                    borderColor: '$background',
                                    color: '$background',
                                }}
                            >
                                <HiBars3 />
                            </RoundButton>
                        </div>
                    )}
                </Styles.MenuWrapper>
            </Styles.Wrapper>
        </Wrapper>
    )
}
export default Header
