import { useSelector } from 'react-redux'
import { StyledFooter } from './Footer.style'
import { getFooter } from 'src/selectors/settings'
import RichText from 'src/components/atoms/RichText/RichText'
import { useResponsive } from 'ahooks'

type FooterProps = {
    showWorkflow?: boolean
    showPortfolio?: boolean
    showPageLinks?: boolean
    isFrontPage?: boolean
}

const Footer: React.VFC<FooterProps> = () => {
    const footer = useSelector(getFooter)
    const responsiveInfo = useResponsive()
    return (
        <StyledFooter>
            <RichText
                htmlString={
                    responsiveInfo.md
                        ? footer.description
                        : footer.descriptionMobile
                }
                textVariant="small2"
            />
        </StyledFooter>
    )
}
export default Footer
