import { styled } from 'src/styles/stitches.config';

export const StyledContactForm = styled('form',{
   
    'strong': {
        fontWeight: '$bold',
    },
    '@sm': {
        width: '75%',
    }
});

export const SubmitWrapper = styled('div',{
    marginTop: '$xs',
    '@md': {
        textAlign: 'right',
        marginTop: 0,
    }
});