import BookingForm from 'src/components/organisms/BookingForm/BookingForm'
import { StyledHorizontalBooking } from './HorizontalBooking.style'

const HorizontalBooking: React.FC = (props) => (
    <div>
        <StyledHorizontalBooking>
            <BookingForm />
        </StyledHorizontalBooking>
    </div>
)

export default HorizontalBooking
