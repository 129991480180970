import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { fetchMenuFromServer, fetchSettingsFromServer } from 'src/api/settings'

import { FooterType, SettingsSliceState } from './types'

const initialState: SettingsSliceState = {
    ready: false,
    slugs: {},
    logo: {},
    language: 'en',
    telephone: '',
    languages: {},
    ajaxurl: '',
    components: {},
    footer: {} as FooterType,
    labels: {},
    menu: {},
    translations: {},
} as SettingsSliceState

export const fetchSettings = createAsyncThunk(
    'settings/fetchSettings',
    async (language: string) => {
        const response =
            window.__INITIAL_DATA__?.settings ||
            (await fetchSettingsFromServer(language))
        return response
    }
)

export const fetchMenu = createAsyncThunk(
    'settings/fetchMenu',
    async (language: string) => {
        const response = await fetchMenuFromServer(language)
        return response
    }
)

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setTranslations: (state, action) => {
            state.translations = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSettings.fulfilled, (state, action) => {
            return {
                ...state,
                ready: true,
                ...action.payload,
            }
        })
        builder.addCase(fetchMenu.fulfilled, (state, action) => {
            return {
                ...state,
                menu: action.payload,
            }
        })
    },
})

export const { setTranslations } = settingsSlice.actions
export default settingsSlice.reducer
