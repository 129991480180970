import { AnimatePresence, motion } from 'framer-motion'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Text from 'src/components/atoms/Text/Text'
import { setIsLoading } from 'src/state/layout'
type TParams = { lang?: string }

const NotFound: React.VFC<RouteComponentProps<TParams>> = ({
    match: {
        params: { lang = 'it' },
    },
}) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setIsLoading(false))
        document.body.classList.add('not-found')
        return () => {
            document.body.classList.remove('not-found')
        }
    }, [dispatch])

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ ease: 'easeOut', duration: 0.6 }}
                style={{ width: '100vw', height: '80vh' }}
            >
                <FlexWrapper
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                    align="center"
                    justify="center"
                >
                    <div style={{ textAlign: 'center' }}>
                        <Text textVariant="heading1">404</Text>
                        <Text
                            textVariant="titleSmall"
                            textTransform="uppercase"
                            css={{
                                letterSpacing: '0.2em',
                                fontWeight: '$normal',
                                marginBottom: '$xs',
                            }}
                        >
                            page not found
                        </Text>
                        <Link to={`/${lang}`}>
                            <Text
                                textVariant="titleSmall"
                                css={{
                                    marginTop: '$xs',
                                    textDecoration: 'underline',
                                    fontWeight: '$bold',
                                    letterSpacing: '0.1em',
                                }}
                            >
                                BACK TO HOME
                            </Text>
                        </Link>
                    </div>
                </FlexWrapper>
            </motion.div>
        </AnimatePresence>
    )
}

export default NotFound
