/* eslint-disable react-hooks/exhaustive-deps */
import { RouteComponentProps } from 'react-router'
import { useContext, useEffect } from 'react'

import { AnimatePresence, motion } from 'framer-motion'
import Padder from 'src/components/_layout/Padder/Padder'
import Loader from 'src/components/atoms/Loader/Loader'
import Composer from 'src/components/helpers/PageComposer/PageComposer'
import { ScrollbarContext, useScrollbar } from 'src/context/scrollbar'
import { useGetPage } from 'src/queries'
import Footer from 'src/components/organisms/Footer/Footer'
import GlobalComponent from 'src/components/_composer/GlobalComponent/GlobalComponent'
import { useDispatch, useSelector } from 'react-redux'
import { getSettingsComponents } from 'src/selectors/settings'
import RoomsListing from 'src/components/_composer/RoomsListing/RoomsListing'
import { PreFooter } from 'src/components/_composer'
import { setIsLoading } from 'src/state/layout'
import ImageLaunch, {
    ImageLaunchProps,
} from 'src/components/_composer/ImageLaunch/ImageLaunch'
import { setTranslations } from 'src/state/settings'

type TParams = { lang: string; page?: string }

const Page: React.VFC<RouteComponentProps<TParams>> = ({
    match: {
        params: { lang, page = 'home-page' },
    },
}) => {
    const { isLoading, error, data, isSuccess } = useGetPage(lang, page)
    const globalComponents = useSelector(getSettingsComponents)
    const dispatch = useDispatch()
    const { scrollbar } = useScrollbar()

    useEffect(() => {
        if (isSuccess) {
            dispatch(setTranslations(data.languages))

            setTimeout(() => {
                scrollbar.current.scrollbar.update()
            }, 1000)
        }
    }, [isSuccess])

    useEffect(() => {
        dispatch(setIsLoading(isLoading))
    }, [isLoading])

    if (error) return <h1>Something went wrong!</h1>
    return (
        <AnimatePresence>
            {isSuccess && (
                <motion.div
                    key="content"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ ease: 'easeOut', duration: 0.6 }}
                >
                    <Padder
                        top={{ '@initial': 'md', '@md': 'xxl' }}
                        style={{ position: 'relative' }}
                    >
                        {data.acf.composer && (
                            <Composer components={data.acf.composer} />
                        )}
                        {data.post_type !== 'property' && (
                            <div>
                                <RoomsListing
                                    {...globalComponents.RoomListing}
                                />
                            </div>
                        )}
                        <div>
                            <PreFooter {...globalComponents.PreFooter} />
                        </div>
                        <Footer />
                    </Padder>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

export default Page
