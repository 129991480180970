'use client'

import Container from 'src/components/_layout/Container/Container'
import * as Styles from './DoubleColumn.style'
import Image from 'src/components/atoms/Image/Image'
import Text from 'src/components/atoms/Text/Text'
import { CmsImage } from 'src/types'
import Padder from 'src/components/_layout/Padder/Padder'

type DoubleColumnProps = {
    columns: {
        content: {
            title?: string
            description: string
            image?: CmsImage
        }[]
    }[]
}

export default function DoubleColumn(props: DoubleColumnProps) {
    const { columns } = props
    return (
        <Container>
            <Styles.Wrapper>
                {columns.map(({ content }, index) => (
                    <Styles.Column key={index}>
                        <Padder top="xs">
                            {content.map(({ title, description, image }, j) => (
                                <Padder top="xs" key={j}>
                                    {!!image && (
                                        <Styles.Image key={index}>
                                            <Image
                                                src={image.url}
                                                alt={image.alt}
                                            />
                                        </Styles.Image>
                                    )}
                                    <Styles.TitleWrapper
                                        as={Text}
                                        textVariant="base"
                                    >
                                        {title}
                                    </Styles.TitleWrapper>
                                    <Text textVariant="base">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: description,
                                            }}
                                        />
                                    </Text>
                                </Padder>
                            ))}
                        </Padder>
                    </Styles.Column>
                ))}
            </Styles.Wrapper>
        </Container>
    )
}
