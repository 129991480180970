import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    position: 'relative',
    borderRadius: '6.25vw',
    backgroundColor: '$white',
    width: '100%',
    height: 'calc(75vh - 40px)',
    minHeight: '400px',
    overflow: 'hidden',
    '@md': {
        height: 'calc(100vh - 40px)',
    },
})

export const SliderWrapper = styled('div', {
    position: 'relative',
    width: '100%',
    height: '100%',

    '.swiper-slide': {
        width: '100%',
        height: '100%',

        img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center bottom',
        },
    },
})

export const SliderFooter = styled('div', {
    position: 'absolute',
    bottom: '3rem',
    paddingLeft: `calc(2 * $colWidth)`,
    paddingRight: `calc(2 * $colWidth)`,
    zIndex: 2,
    color: '#fff',
    width: '100%',
    letterSpacing: '-0.02em',
    '@md': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
})

export const Navigator = styled('div', {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.8rem',

    '& .line': {
        width: `calc(1.5 * $colWidth)`,
        minWidth: '3.5rem',
        height: '1px',
        backgroundColor: '#fff',
    },

    '& .indicator': {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        '>div': {
            width: '0.8rem',
        },
    },

    fontSize: '1.3rem',
    '@mdDown': {
        marginTop: '1rem',
    },
})
