import { breakpoints, styled } from 'src/styles/stitches.config'
import { fluidSize } from 'src/styles/styleUtils'

export const Title = styled('div', {
    color: '#fff',
    fontSize: '35px',
    '@sm': {
        fontSize: fluidSize(40, 100, breakpoints.sm, breakpoints.xxl),
    },
    '@xxl': {
        fontSize: '100px',
    },
})
