import { styled } from 'src/styles/stitches.config'

export default styled('div', {
    position: 'relative',
    width: '100%',
    paddingLeft: '20px',
    paddingRight: '20px',
    boxSizing: 'border-box',
    variants: {
        small: {
            true: {
                '@md': {
                    paddingLeft: 'calc(2 * $colWidth)',
                    paddingRight: 'calc(2 * $colWidth)',
                },
            },
        },
    },
})
