import { createStitches } from '@stitches/react'

import styleUtils from './styleUtils'

export const styleConstants = {
    colWidth: 4.16666666667,
}

export const breakpoints: Record<string, number> = {
    xs: 576,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1440,
    xxl: 1600,
}

export const {
    styled,
    css,
    globalCss,
    keyframes,
    getCssText,
    theme,
    createTheme,
    config,
} = createStitches({
    theme: {
        fonts: {
            primary: 'Metropolis',
            secondary: 'BakerSignet BT',
        },
        colors: {
            primary: '#000000',
            primary_light: '#888888',
            black: '#000000',
            white: '#ffffff',
            background: '#faf6e8',
        },
        fontWeights: {
            normal: 'normal',
            medium: '500',
            light: '200',
            bold: 'bold',
        },
        sizes: {
            colWidth: `${100 / 24}vw`,
        },
        space: {
            colWidth: 'calc(100vw / 24)',
            xxs: '0.5rem',
            xs: '1rem',
            sm: '2rem',
            md: '4rem',
            lg: '6rem',
            xl: '9.37rem',
            xxl: '12.5rem',
            zero: '0px',
        },
    },
    media: {
        ...Object.fromEntries([
            ...Object.keys(breakpoints).map((key: string) => [
                key,
                `(min-width:${breakpoints[key]}px)`,
            ]),
            ...Object.keys(breakpoints).map((key: string) => [
                `${key}Down`,
                `(max-width:${breakpoints[key] - 1}px)`,
            ]),
        ]),
        hover: '(hover: hover)',
    },
    utils: {
        ...styleUtils,
    },
})

export const globalStyles = globalCss({
    body: {
        fontFamily:
            'Metropolis, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
        fontWeight: 'normal',
        overflow: 'hidden',
    },

    '#root': {
        backgroundColor: '#fff',
        overflow: 'hidden',
    },
    '.App': {
        height: '100vh',
        backgroundColor: '$background',
        overflow: 'hidden',
    },

    '.scroll-content': {
        paddingTop: '150px',
        backgroundColor: '$background',

        '@md': {
            paddingTop: '20px',
        },
    },

    '.hidden-xs': {
        '@smDown': {
            display: 'none !important',
        },
    },
    '.hidden-sm': {
        '@mdDown': {
            display: 'none !important',
        },
    },
    '.visible-sm': {
        display: 'none !important',
        '@mdDown': {
            display: 'block !important',
        },
    },
})
