import { useMemo, useRef, useState } from 'react'
import Container from 'src/components/_layout/Container/Container'
import { CmsImage } from 'src/types'
import * as Styles from './Slider.style'
import SwiperInstance, { Autoplay, EffectFade } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AnimatePresence, motion } from 'framer-motion'
import { HiChevronRight } from 'react-icons/hi2'
import { Title } from 'src/components/atoms/Title/Title'
import Letters from 'src/components/atoms/Letters/Letters'
import { useResponsive } from 'ahooks'

SwiperInstance.use([EffectFade, Autoplay])

type SliderProps = {
    items: {
        image: CmsImage
        title?: string
    }[]
    title?: string
}

const container = {
    hidden: {
        opacity: 1,
        transition: {
            staggerChildren: 0.02,
        },
    },
    show: {
        transition: {
            staggerChildren: 0.02,
        },
    },
}

const item = {
    hidden: {
        y: '110%',
        transition: {
            ease: 'easeOut',
        },
    },
    show: {
        y: '0%',
        transition: {
            ease: 'easeOut',
        },
    },
}

const Slider: React.FC<SliderProps> = ({ items, title }) => {
    const swiperRef = useRef<SwiperInstance>()
    const [currentSlide, setCurrentSlide] = useState(0)
    const responsiveInfo = useResponsive()

    const currentTitle = useMemo(
        () => title || items[currentSlide].title,
        [currentSlide]
    )

    const renderTitle = (title: string) => {
        if (responsiveInfo.md) {
            return (
                <AnimatePresence exitBeforeEnter>
                    <Letters string={title} key={title} exitAnimation />
                </AnimatePresence>
            )
        }
        return (
            <AnimatePresence exitBeforeEnter>
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    key={currentTitle}
                >
                    {currentTitle}
                </motion.div>
            </AnimatePresence>
        )
    }
    return (
        <Container>
            <Styles.Wrapper>
                <Styles.SliderWrapper
                    as={Swiper}
                    effect="fade"
                    slidesPerView={1}
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper
                    }}
                    autoplay={{ delay: 6000 }}
                    speed={3000}
                    allowTouchMove={false}
                    loop={items?.length >= 2}
                    onSlideChange={(swiper) =>
                        setCurrentSlide(swiper.realIndex)
                    }
                    css={{ pointerEvents: items?.length >= 2 ? 'all' : 'none' }}
                >
                    {items.map(({ image: { id, url, alt } }, index) => (
                        <SwiperSlide key={id || index}>
                            <img src={url} alt={alt} />
                        </SwiperSlide>
                    ))}
                </Styles.SliderWrapper>
                <Styles.SliderFooter>
                    {currentTitle && <Title>{renderTitle(currentTitle)}</Title>}

                    <Styles.Navigator>
                        <div className="line" />
                        <div className="indicator">
                            <AnimatePresence exitBeforeEnter>
                                <Letters
                                    string={`${currentSlide + 1}`}
                                    exitAnimation
                                    key={currentSlide}
                                />
                            </AnimatePresence>
                            /{items.length}
                        </div>
                        <button
                            aria-label="Next slide"
                            onClick={() => {
                                if (swiperRef.current)
                                    swiperRef.current.slideNext()
                            }}
                        >
                            <HiChevronRight />
                        </button>
                    </Styles.Navigator>
                </Styles.SliderFooter>
            </Styles.Wrapper>
        </Container>
    )
}

export default Slider
