import { styled } from 'src/styles/stitches.config'

const Textarea = styled('textarea', {
    display: 'block',
    width: '100%',
    height: '200px',
    backgroundColor: 'transparent',
    padding: '10px',
    border: '1.5px solid $black',
    color: '$black',
    fontSize: '1.3rem',
    '&::placeholder': {
        fontSize: '1rem',
        opacity: 1,
        color: '$black',
        fontFamily: '$primary',
    },
})

export default Textarea
