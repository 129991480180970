import {
    MutableRefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'
import FieldError from 'src/components/molecules/FieldError/FieldError'
import { Picker, StyledInput } from './Input.style'
import { DateRange, DayPicker } from 'react-day-picker'
import { addDays, format } from 'date-fns'

type InputProps = {
    register?: any
    error?: any
    required?: any
    setValue: Function
} & React.HTMLProps<HTMLInputElement>

const today = new Date()
const defaultSelected: DateRange = {
    from: today,
    to: addDays(today, 4),
}

const Input: React.VFC<InputProps> = ({
    type,
    placeholder,
    name,
    error,
    required,
    value,
    register = () => {},
    setValue,
}) => {
    const [showPicker, setShowPicker] = useState(false)
    const inputRef = useRef() as MutableRefObject<HTMLInputElement>
    const { ref = null } = {
        ...register(name, {
            required,
        }),
    }

    const [range, setRange] = useState<DateRange | undefined>(defaultSelected)

    useEffect(() => {
        if (inputRef.current && value) {
            inputRef.current.value = value as string
            setValue(name, value)
        }
    }, [name, setValue, value])

    const handleBlur = useCallback((e) => {
        const currentTarget = e.currentTarget

        // Give browser time to focus the next element
        requestAnimationFrame(() => {
            // Check if the new focused element is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                setShowPicker(false)
            }
        })
    }, [])

    const handleMouseEnter = (day: Date) => {
        if (range?.from) {
            // Se from non è impostato o to è già impostato, imposta from come la data entrante
            setRange({ from: range.from, to: day })
        }
    }

    return (
        <div>
            <StyledInput onBlur={handleBlur}>
                <input
                    ref={
                        ref === null
                            ? inputRef
                            : (e) => {
                                  ref(e)
                                  inputRef.current = e as HTMLInputElement
                              }
                    }
                    type={type === 'date' ? 'text' : type}
                    name={name}
                    placeholder={placeholder}
                    readOnly={type === 'date'}
                    onFocus={() => {
                        if (type === 'date') {
                            setShowPicker(true)
                            setRange(undefined)
                        }
                    }}
                    onChange={(e) => {
                        if (type !== 'date') {
                            setValue(name, e.target.value)
                        }
                    }}
                />
                <label>{placeholder}</label>
                {showPicker && (
                    <Picker>
                        <DayPicker
                            mode="range"
                            min={2}
                            fromDate={new Date()}
                            defaultMonth={addDays(today, 1)}
                            selected={range}
                            onSelect={(newRange) => {
                                if (
                                    !!range?.from &&
                                    !!newRange?.from &&
                                    !!inputRef.current
                                ) {
                                    setValue(
                                        name,
                                        `${format(
                                            range.from,
                                            'dd/MM/yyyy'
                                        )} - ${format(
                                            newRange.from,
                                            'dd/MM/yyyy'
                                        )}`
                                    )
                                    setShowPicker(false)
                                } else if (!range?.from && newRange?.from) {
                                    setRange({
                                        from: newRange.from,
                                        to: undefined,
                                    })
                                }
                            }}
                            numberOfMonths={2}
                            onDayMouseEnter={handleMouseEnter}
                        />
                    </Picker>
                )}
            </StyledInput>
            {error && <FieldError errorType={error.type} />}
        </div>
    )
}

export default Input
