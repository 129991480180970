import Text from 'src/components/atoms/Text/Text'
import * as Styles from './Contacts.style'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import Button from 'src/components/atoms/Button/Button'
import useLabel from 'src/hooks/useLabel'

type ServicesProps = {
    title: string
    items: {
        item: string
    }[]
    email: string
}

const Services: React.FC<ServicesProps> = ({ items, title, email }) => {
    const label = useLabel()
    return (
        <Styles.Wrapper>
            <FlexWrapper
                align={{ '@initial': 'start', '@md': 'end' }}
                direction={{ '@initial': 'column', '@md': 'row' }}
            >
                <Padder top="sm" css={{ '@mdDown': { marginBottom: '$sm' } }}>
                    <RichText htmlString={title} textVariant="heading1" />
                    <Styles.ItemsWrapper
                        as={Padder}
                        left={{ '@initial': 'zero', '@md': 'sm' }}
                    >
                        {items.map(({ item }, i) => (
                            <Text textVariant="base" as="span" key={i}>
                                {item}
                            </Text>
                        ))}
                    </Styles.ItemsWrapper>
                </Padder>
                <div>
                    <Button as="a" href={`mailto:${email}`} title="send email">
                        {label('Send Email')}
                    </Button>
                </div>
            </FlexWrapper>
        </Styles.Wrapper>
    )
}

export default Services
