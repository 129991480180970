import { useEffect } from 'react'

import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

import { StyledLetters } from './Letters.style'

type LetterProps = {
    string: string
    delay?: number
    exitAnimation?: boolean
}

const Letters: React.VFC<LetterProps> = ({
    string,
    delay = 0,
    exitAnimation,
}) => {
    const [ref, inView, entry] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    })
    const controls = useAnimation()
    const letters = string.split('')

    useEffect(() => {
        if (!!entry && inView) {
            controls.start('show')
        }
    }, [entry, inView])

    return (
        <StyledLetters
            as={motion.div}
            variants={{
                hidden: {
                    opacity: 0,
                    transition: {
                        staggerChildren: 0.02,
                    },
                },
                show: {
                    opacity: 1,
                    transition: {
                        delay: delay,
                        staggerChildren: 0.015,
                    },
                },
            }}
            initial="hidden"
            exit={exitAnimation ? 'hidden' : undefined}
            animate={controls}
            ref={ref}
        >
            {letters.map((letter, index) => (
                <motion.span
                    key={index}
                    variants={{
                        hidden: {
                            y: '110%',
                            transition: {
                                ease: 'easeOut',
                            },
                        },
                        show: {
                            y: '0%',
                            transition: {
                                ease: 'easeOut',
                            },
                        },
                    }}
                >
                    {letter !== ' ' ? (
                        letter
                    ) : (
                        <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
                    )}
                </motion.span>
            ))}
        </StyledLetters>
    )
}

export default Letters
