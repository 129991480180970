import { styled } from 'src/styles/stitches.config'

export const StyledLoader = styled('div', {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '$background',
    zIndex: '999999',
})
