import { Link } from 'react-router-dom'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import Image from 'src/components/atoms/Image/Image'
import RichText from 'src/components/atoms/RichText/RichText'
import SlashLink from 'src/components/atoms/SlashLink/SlashLink'
import Text from 'src/components/atoms/Text/Text'
import Revealer from 'src/components/helpers/Revealer/Revealer'
import { CmsImage, CmsLink } from 'src/types'
import { InfoWrapper, ItemImage, ItemRow, Title } from './ImageLaunch.style' // Adjust path

export type ImageLaunchProps = {
    title: string
    image: CmsImage
    link: CmsLink
    description: string
    subtitle?: string
    reverse?: boolean
}

export default function ImageLaunch(props: ImageLaunchProps) {
    const { image, title, link, description, subtitle, reverse } = props

    return (
        <Container small>
            <ItemRow
                css={{
                    '@md': { flexDirection: reverse ? 'row-reverse' : 'row' },
                }}
            >
                <Revealer>
                    <ItemImage as={Link} to={link.url}>
                        <Image src={image.url} alt={title} />
                    </ItemImage>
                </Revealer>

                <Padder top={{ '@initial': 'xs', '@md': 'md' }}>
                    <Title>
                        <Text textVariant="title" css={{ fontWeight: '$bold' }}>
                            {subtitle}
                        </Text>
                        <Text textVariant="title">{title}</Text>
                    </Title>
                    <InfoWrapper>
                        <RichText htmlString={description} />
                        <div>
                            <SlashLink link={link} />
                        </div>
                    </InfoWrapper>
                </Padder>
            </ItemRow>
        </Container>
    )
}
