import { useState } from 'react'

import { Controller, useForm } from 'react-hook-form'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import RadioCheckWrapper from 'src/components/atoms/RadioCheckWrapper'
import RichText from 'src/components/atoms/RichText/RichText'
import Submit from 'src/components/atoms/Submit/Submit'
import Text from 'src/components/atoms/Text'
import Textarea from 'src/components/atoms/Textarea'
import FieldError from 'src/components/molecules/FieldError/FieldError'

import { API_URL } from 'src/constants'

import Select from 'react-select'
import Input from 'src/components/atoms/Input/Input'
import useLabel from 'src/hooks/useLabel'
import {
    FormRow,
    StyledAccomodationForm,
    SubmitWrapper,
    selectStyles,
} from './AccomodationForm.style'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'

const AccomodationForm: React.VFC<{
    title: string
    description: string
    destEmail: string
    accomodationTypes: string[]
}> = ({ title, description, destEmail, accomodationTypes }) => {
    const [response, setResponse] = useState<string | undefined>(undefined)
    const [isSending, setIsSending] = useState<boolean>(false)

    const label = useLabel()

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data: any) => {
        if (isSending) {
            return
        }
        setIsSending(true)
        try {
            const response = await fetch(API_URL + '/sendcontact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    destEmail,
                    subject: 'Richiesta dal sito',
                }),
            })
            const responseJson = await response.json()
            setResponse(responseJson.text)
            setIsSending(false)
        } catch (err) {
            console.log(err)
            setIsSending(false)
        }
    }
    console.log(errors)

    return (
        <Container as={Padder} top="md" small>
            <Padder top="md" css={{ '@mdDown': { marginBottom: '$sm' } }}>
                <RichText htmlString={title} textVariant="heading1" />
                <RichText htmlString={description} />
            </Padder>
            <StyledAccomodationForm onSubmit={handleSubmit(onSubmit)}>
                <Padder top="sm">
                    <FlexWrapper
                        as={FormRow}
                        direction={{ '@initial': 'column', '@lg': 'row' }}
                        justify={{ '@lg': 'between' }}
                    >
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['nome']}
                                type="text"
                                name="nome"
                                placeholder={label('firstname')}
                            />
                        </div>
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['cognome']}
                                type="text"
                                name="cognome"
                                placeholder={label('lastname')}
                            />
                        </div>
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['email']}
                                type="email"
                                name="email"
                                placeholder={label('email')}
                            />
                        </div>
                    </FlexWrapper>
                    <FlexWrapper
                        as={FormRow}
                        direction={{ '@initial': 'column', '@lg': 'row' }}
                        justify={{ '@lg': 'between' }}
                    >
                        <div>
                            <label>{label('accomodation_type')}</label>
                            <Controller
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        isSearchable={false}
                                        options={accomodationTypes.map(
                                            (type) => ({
                                                value: type,
                                                label: type,
                                            })
                                        )}
                                        onChange={(singleValue) => {
                                            onChange(singleValue?.value)
                                        }}
                                        styles={selectStyles}
                                    />
                                )}
                                name={'accomodation_type'}
                            />
                        </div>
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['bedrooms']}
                                type="number"
                                name="bedrooms"
                                placeholder={label('bedrooms')}
                            />
                        </div>
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['bathrooms']}
                                type="number"
                                name="bathrooms"
                                placeholder={label('bathrooms')}
                            />
                        </div>
                    </FlexWrapper>
                    <FlexWrapper
                        as={FormRow}
                        direction={{ '@initial': 'column', '@lg': 'row' }}
                        justify={{ '@lg': 'between' }}
                    >
                        <div>
                            <label>{label('parking')}</label>
                            <Controller
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        isSearchable={false}
                                        options={[
                                            {
                                                value: label('yes'),
                                                label: label('yes'),
                                            },
                                            {
                                                value: label('no'),
                                                label: label('no'),
                                            },
                                        ]}
                                        onChange={(singleValue) => {
                                            onChange(singleValue?.value)
                                        }}
                                        styles={selectStyles}
                                    />
                                )}
                                name="parking"
                            />
                        </div>
                        <div>
                            <label>{label('experienced')}</label>
                            <Controller
                                control={control}
                                render={({ field: { onChange } }) => (
                                    <Select
                                        isSearchable={false}
                                        options={[
                                            {
                                                value: label('yes'),
                                                label: label('yes'),
                                            },
                                            {
                                                value: label('no'),
                                                label: label('no'),
                                            },
                                        ]}
                                        onChange={(singleValue) => {
                                            onChange(singleValue?.value)
                                        }}
                                        styles={selectStyles}
                                    />
                                )}
                                name="experienced"
                            />
                        </div>
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['location']}
                                type="text"
                                name="location"
                                placeholder={label('location')}
                            />
                        </div>
                    </FlexWrapper>

                    <div>
                        <Textarea
                            placeholder={label('notes')}
                            {...register('messaggio', {
                                required: true,
                            })}
                        />
                        <div>
                            {errors && errors['messaggio'] && (
                                <FieldError
                                    errorType={
                                        (errors['messaggio']?.type as string) ||
                                        ''
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <RadioCheckWrapper>
                            <input
                                {...register('privacy', {
                                    required: true,
                                })}
                                type="checkbox"
                                name="privacy"
                                value={'Accettato'}
                            />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: label('privacy'),
                                }}
                            />
                        </RadioCheckWrapper>
                        {errors && errors['privacy'] && (
                            <FieldError
                                errorType={
                                    (errors['privacy']?.type as string) || ''
                                }
                            />
                        )}
                    </div>
                    <div>
                        <SubmitWrapper>
                            <Submit type="submit">
                                {isSending ? label('sending') : label('send')}
                            </Submit>
                            {errors && Object.keys(errors).length > 0 && (
                                <FieldError errorType="general" />
                            )}
                            {response && !isSending && (
                                <Text style={{ marginTop: '15px' }}>
                                    {response}
                                </Text>
                            )}
                        </SubmitWrapper>
                    </div>
                </Padder>
            </StyledAccomodationForm>
        </Container>
    )
}

export default AccomodationForm
