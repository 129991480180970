import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    width: '100%',
    paddingLeft: `calc(2 * $colWidth)`,
    paddingRight: `calc(2 * $colWidth)`,
})

export const ItemsWrapper = styled('div', {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    rowGap: '$xs',
    '@md': {
        width: 'calc(14 * $colWidth)',
    },
})
