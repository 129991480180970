import { styled } from 'src/styles/stitches.config'

export const StyledInput = styled('div', {
    position: 'relative',
    height: '60px',
    label: {
        position: 'absolute',
        top: 'calc(100% - 10px - 1rem)',
        left: '5px',
        transition: 'all .3s ease',
    },
    input: {
        width: '100%',
        height: '60px',
        verticalAlign: 'baseline',
        padding: 'calc(45px - 1rem) 5px 5px',
        fontSize: '1.3rem',
        border: 'none',
        borderBottom: '1.5px solid $black',
        backgroundColor: 'transparent',
        color: '$black',
        '&:focus + label,&:not(:placeholder-shown) + label': {
            top: '5px',
            fontSize: '0.85rem',
            letterSpacing: 0,
        },
        '&::placeholder': {
            opacity: 0,
        },
    },
})

export const StyledDatePicker = styled('div', {
    marginTop: '0!important',
    paddingLeft: '$xs',
    width: '100%',
    input: {
        padding: 0,
    },
    '.react-date-picker': {
        width: '100%',
    },
    '.react-date-picker__wrapper': {
        border: 'none',
    },
    '.react-date-picker__calendar': {
        zIndex: 2,
    },
    '.react-calendar button, .react-calendar__month-view__weekdays': {
        color: '$blueDark',
        '&.react-calendar__month-view__days__day--weekend': {
            color: '#900',
        },
    },
    '.react-calendar__navigation__label': {
        textTransform: 'capitalize',
    },
})

export const Picker = styled('div', {
    position: 'absolute',
    top: '100%',
    left: '0',
    width: 'auto',
    backgroundColor: '#fff',
    paddingTop: '$sm',
    paddingBottom: '$sm',
})
