import { CmsImage } from 'src/types'
import * as Styles from './RoomIntro.style'
import Padder from 'src/components/_layout/Padder/Padder'
import Text from 'src/components/atoms/Text/Text'
import Image from 'src/components/atoms/Image/Image'

type Column = {
    title: string
    content: string
}
type RoomIntroProps = {
    firstColumn: Column
    secondCOlumn: Column
    image: CmsImage
}

const Content: React.FC<Column> = ({ title, content }) => (
    <Padder top="xs">
        <Styles.TitleWrapper as={Text} textVariant="base">
            {title}
        </Styles.TitleWrapper>
        <Text textVariant="base">
            <div
                dangerouslySetInnerHTML={{
                    __html: content,
                }}
            />
        </Text>
    </Padder>
)

const RoomIntro: React.FC<RoomIntroProps> = ({
    firstColumn,
    secondCOlumn,
    image,
}) => {
    return (
        <Styles.Wrapper>
            <Styles.FirstColumn>
                <Content {...firstColumn} />
            </Styles.FirstColumn>
            <Styles.SecondColumn>
                <Content {...secondCOlumn} />
            </Styles.SecondColumn>
            <Styles.ImageWrapper>
                <Image src={image.url} alt={image.alt} />
            </Styles.ImageWrapper>
        </Styles.Wrapper>
    )
}

export default RoomIntro
