import { useState } from 'react'

import { useForm } from 'react-hook-form'
import Container from 'src/components/_layout/Container/Container'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'
import Submit from 'src/components/atoms/Submit/Submit'
import Text from 'src/components/atoms/Text'
import Textarea from 'src/components/atoms/Textarea'
import FieldError from 'src/components/molecules/FieldError/FieldError'
import RadioCheckWrapper from 'src/components/atoms/RadioCheckWrapper'

import { API_URL } from 'src/constants'

import useLabel from 'src/hooks/useLabel'
import { StyledContactForm, SubmitWrapper } from './ContactForm.style'
import Input from 'src/components/atoms/Input/Input'

const ContactForm: React.VFC<{
    title: string
    description: string
    destEmail: string
}> = ({ title, description, destEmail }) => {
    const [response, setResponse] = useState<string | undefined>(undefined)
    const [isSending, setIsSending] = useState<boolean>(false)

    const label = useLabel()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()

    const onSubmit = async (data: any) => {
        if (isSending) {
            return
        }
        setIsSending(true)
        try {
            const response = await fetch(API_URL + '/sendcontact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...data,
                    destEmail,
                    subject: 'Richiesta dal sito',
                }),
            })
            const responseJson = await response.json()
            setResponse(responseJson.text)
            setIsSending(false)
        } catch (err) {
            console.log(err)
            setIsSending(false)
        }
    }
    console.log(errors)

    return (
        <Container as={Padder} top="md" small>
            <Padder top="md" css={{ '@mdDown': { marginBottom: '$sm' } }}>
                <RichText htmlString={title} textVariant="heading1" />
                <RichText htmlString={description} />
            </Padder>
            <StyledContactForm onSubmit={handleSubmit(onSubmit)}>
                <Padder top="sm">
                    <Padder top="xxs">
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['nome']}
                                type="text"
                                name="nome"
                                placeholder={label('firstname')}
                            />
                        </div>
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['cognome']}
                                type="text"
                                name="cognome"
                                placeholder={label('lastname')}
                            />
                        </div>
                        <div>
                            <Input
                                setValue={setValue}
                                register={register}
                                required
                                error={errors && errors['email']}
                                type="email"
                                name="email"
                                placeholder={label('email')}
                            />
                        </div>
                    </Padder>
                    <div>
                        <Textarea
                            placeholder={label('message')}
                            {...register('messaggio', {
                                required: true,
                            })}
                        />
                        <div>
                            {errors && errors['messaggio'] && (
                                <FieldError
                                    errorType={
                                        (errors['messaggio']?.type as string) ||
                                        ''
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <RadioCheckWrapper>
                            <input
                                {...register('privacy', {
                                    required: true,
                                })}
                                type="checkbox"
                                name="privacy"
                                value={'Accettato'}
                            />
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: label('privacy'),
                                }}
                            />
                        </RadioCheckWrapper>
                        {errors && errors['privacy'] && (
                            <FieldError
                                errorType={
                                    (errors['privacy']?.type as string) || ''
                                }
                            />
                        )}
                    </div>
                    <div>
                        <SubmitWrapper>
                            <Submit type="submit">
                                {isSending ? label('sending') : label('send')}
                            </Submit>
                            {errors && Object.keys(errors).length > 0 && (
                                <FieldError errorType="general" />
                            )}
                            {response && !isSending && (
                                <Text style={{ marginTop: '15px' }}>
                                    {response}
                                </Text>
                            )}
                        </SubmitWrapper>
                    </div>
                </Padder>
            </StyledContactForm>
        </Container>
    )
}

export default ContactForm
