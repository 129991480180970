import { styled } from 'src/styles/stitches.config'

export const StyledRichText = styled('p', {
    'strong, b, h1, h2, h3, h4, h5, h6': {
        fontWeight: '$bold',
    },
    em: {
        fontStyle: 'italic',
    },
    p: {
        marginBottom: '$xs',
    },
    ul: {
        listStyle: 'inside',
    },
})
