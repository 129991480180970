import Button from 'src/components/atoms/Button/Button'
import { StyledInput } from 'src/components/atoms/Input/Input.style'
import Text from 'src/components/atoms/Text/Text'
import { styled } from 'src/styles/stitches.config'

export const StyledHorizontalBooking = styled('div', {
    backgroundColor: '#1a1a1a',
    paddingTop: '$md',
    paddingBottom: '$md',
    [`${Text}`]: { color: '$background' },
    [`${StyledInput} input`]: {
        borderBottomColor: '$background',
        color: '$background',
    },
    [`${Button}`]: {
        '&::before, &::after': {
            color: '$background',
        },
    },
})
