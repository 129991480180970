import Text from 'src/components/atoms/Text'
import useLabel from 'src/hooks/useLabel'

type FieldErrorProps = {
    errorType: string
}

const FieldError: React.VFC<FieldErrorProps> = ({ errorType }) => {
    const label = useLabel()
    return (
        <Text textVariant="small" style={{ color: '#900', marginTop: '15px' }}>
            {label(`error.${errorType}`)}
        </Text>
    )
}

export default FieldError
