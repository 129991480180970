import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    paddingLeft: '20px',
    paddingRight: '20px',

    '@md': {
        paddingLeft: 'calc(6 * $colWidth)',
        paddingRight: '$colWidth',
    },
})

export const TitleWrapper = styled('div', {
    display: 'flex',
    gap: '2rem',
    paddingBottom: '$md',
    '@mdDown': {
        overflow: 'scroll',
        paddingBottom: '$sm',
    },
})

export const MobileContent = styled('div', {
    height: 'auto',
    maxHeight: '0px',
    overflow: 'hidden',
    transition: 'all 0.6s ease-out',
    variants: {
        open: {
            true: {
                maxHeight: '1000px',
            },
        },
    },
})
