import Text from '../Text/Text'
import { StyledRichText } from './RichText.style'
import type * as Stitches from '@stitches/react'
type RichTextProps = {
    htmlString: string
    textVariant?: string
    css?: Stitches.CSS
}
const RichText: React.VFC<RichTextProps> = ({
    htmlString,
    textVariant = 'base',
    css,
}) => {
    return (
        //@ts-ignore
        <Text textVariant={textVariant} css={css}>
            <StyledRichText dangerouslySetInnerHTML={{ __html: htmlString }} />
        </Text>
    )
}

export default RichText
