import React from 'react'
import { Provider } from 'react-redux'

import 'src/styles/reset.css'
import 'src/styles/fonts.css'
import 'swiper/swiper-bundle.min.css'

import { configResponsive } from 'ahooks'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import App from 'src/App'
import { store } from 'src/store'

import reportWebVitals from './reportWebVitals'
import { breakpoints, globalStyles } from './styles/stitches.config'

const queryClient = new QueryClient()
globalStyles()

if (window.__INITIAL_DATA__) {
    if (
        typeof window.__INITIAL_DATA__ === 'object' &&
        'page' in window.__INITIAL_DATA__
    ) {
        const p: any = window.__INITIAL_DATA__.page
        queryClient.setQueryData(
            p.post_name,
            window.__INITIAL_DATA__[p.post_name]
        )
    }
}

configResponsive(breakpoints)

const rootElement = document.getElementById('root')
ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <App />
            </Provider>
        </QueryClientProvider>
    </React.StrictMode>,
    rootElement
)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
