import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    borderRadius: '5vw',
    overflow: 'hidden',
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
})
