import Text from 'src/components/atoms/Text/Text'
import * as Styles from './Services.style'
import Padder from 'src/components/_layout/Padder/Padder'
import RichText from 'src/components/atoms/RichText/RichText'

type ServicesProps = {
    title: string
    items: {
        title: string
    }[]
}

const Services: React.FC<ServicesProps> = ({ items, title }) => {
    return (
        <Styles.Wrapper>
            <Padder top="sm">
                <RichText htmlString={title} textVariant="heading1" />
                <Styles.ItemsWrapper
                    as={Padder}
                    left={{ '@initial': 'zero', '@md': 'md' }}
                >
                    {items.map((item, i) => (
                        <Text textVariant="base" as="span">
                            {item.title}
                        </Text>
                    ))}
                </Styles.ItemsWrapper>
            </Padder>
        </Styles.Wrapper>
    )
}

export default Services
