import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    width: '100%',
    paddingLeft: `calc(2 * $colWidth)`,
    paddingRight: `calc(2 * $colWidth)`,
})

export const ItemsWrapper = styled('div', {
    '@md': {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%',
        rowGap: '$xs',
    },
    '@mdDown': {
        span: {
            display: 'inlne-block',
            paddingRight: '1rem',
        },
    },
})
