import { styleConstants, styled } from 'src/styles/stitches.config'

export const TitleWrapper = styled('div', {
    paddingLeft: 'calc(2 * $colWidth)',
    paddingRight: 'calc(2 * $colWidth)',
})

export const StyledGallery = styled('div', {
    position: `relative`,
    '.swiper-slide': {
        width: '100%',
        transition: 'opacity .3s linear',

        '&:not(.swiper-slide-active)': {
            opacity: 0.5,
        },
        ratio: {
            w: 1445,
            h: 1045,
        },
        overflow: 'hidden',
        borderRadius: '6vw',
        '> img': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
        },
        '@sm': {
            width: `${14 * styleConstants.colWidth}vw`,
            ratio: {
                w: 3,
                h: 2,
            },
        },
    },
})
