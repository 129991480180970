import { useState } from 'react'
import * as Styles from './Tabs.style'
import Text from 'src/components/atoms/Text/Text'
import { AnimatePresence, motion } from 'framer-motion'
import RichText from 'src/components/atoms/RichText/RichText'
import Button from 'src/components/atoms/Button/Button'
import { useResponsive } from 'ahooks'
import Padder from 'src/components/_layout/Padder/Padder'
import { CmsImage, CmsLink } from 'src/types'
import ImageLink from '../ImageLink'
import FlexWrapper from 'src/components/_layout/FlexWrapper/FlexWrapper'
import MobileItem from './MobileItem'

type TabsProps = {
    items: {
        title: string
        content?: string
        contentType?: 'text' | 'image'
        image?: CmsImage
        imageLink?: CmsLink
    }[]
}

const Tabs: React.FC<TabsProps> = ({ items }) => {
    const [currentIndex, setCurrentIndex] = useState(0)
    const responsiveInfo = useResponsive()
    const getContent = ({
        contentType,
        content,
        image,
        imageLink,
    }: TabsProps['items'][0]) => {
        if (contentType === 'text' && !!content) {
            return <RichText htmlString={content} />
        }

        if (contentType === 'image' && !!image && !!imageLink) {
            return <ImageLink image={image} link={imageLink} />
        }
    }

    return responsiveInfo.md ? (
        <Styles.Wrapper>
            <Styles.TitleWrapper>
                {items.map(({ title }, index) => (
                    <Button
                        aria-label={title}
                        css={{
                            opacity: currentIndex !== index ? 0.6 : 1,
                            transition: 'opacity 0.2s linear',
                            '&::after': {
                                display: 'none',
                            },
                        }}
                        onClick={() => {
                            setCurrentIndex(index)
                        }}
                    >
                        {title}
                    </Button>
                ))}
            </Styles.TitleWrapper>
            <div>
                <AnimatePresence exitBeforeEnter>
                    <motion.div
                        key={currentIndex}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                    >
                        {getContent(items[currentIndex])}
                    </motion.div>
                </AnimatePresence>
            </div>
        </Styles.Wrapper>
    ) : (
        <Styles.Wrapper as={Padder} top="sm">
            {items.map(({ title }, index) => (
                <MobileItem
                    key={title}
                    title={title}
                    content={getContent(items[index])}
                    defaultOpen={index === 0}
                />
            ))}
        </Styles.Wrapper>
    )
}

export default Tabs
