import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'

import WebsiteContainer from 'src/components/_layout/WebsiteContainer'
import GlobalScroller from 'src/components/helpers/GlobalScroller/GlobalScroller'

import { getSettings } from 'src/selectors/settings'

import Header from './components/organisms/Header'
import { BASEPATH } from './constants'
import ScrollbarProvider from './context/scrollbar'
import RouterComponent from './router'
import { fetchMenu, fetchSettings } from './state/settings'
import { useResponsive } from 'ahooks'
import LayerMenu from './components/organisms/LayerMenu/LayerMenu'
import Whatsapp from './components/molecules/Whatsapp/Whatsapp'
import Loader from './components/atoms/Loader/Loader'
import { selectIsLoading } from './state/layout'

export default function App() {
    const dispatch = useDispatch()
    const { ready } = useSelector(getSettings)
    const responsiveInfo = useResponsive()
    const isLoading = useSelector(selectIsLoading)
    useEffect(() => {
        const match = matchPath(window.location.pathname, {
            path: `${BASEPATH}/:lang(it|en)/:page?`,
        })
        //@ts-ignore
        const lang = match?.params?.lang
        dispatch(fetchSettings(lang || ''))
        dispatch(fetchMenu(lang || ''))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return ready ? (
        <>
            <div className="App">
                <Router>
                    {!responsiveInfo.md && <LayerMenu />}
                    <ScrollbarProvider>
                        <GlobalScroller>
                            <WebsiteContainer>
                                <Header />
                                <RouterComponent />
                            </WebsiteContainer>
                        </GlobalScroller>
                    </ScrollbarProvider>
                    <Whatsapp />
                    {(!ready || isLoading) && <Loader />}
                </Router>
            </div>
        </>
    ) : null
}
