import { Route, Switch, useLocation } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'

import { AnimatePresence } from 'framer-motion'
import { getSettings } from 'src/selectors/settings'

import getRoutes from './routes'

const RouterComponent: React.VFC = () => {
    const { slugs } = useSelector(getSettings)
    const location = useLocation()
    return (
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                {getRoutes(slugs).map((props) => {
                    return <Route {...props} />
                })}
            </Switch>
        </AnimatePresence>
    )
}

export default RouterComponent
