import { createContext, MutableRefObject, useRef, useContext } from 'react'

export interface ScrollbarType {
    scrollbar: any
}

const initialContext = {
    scrollbar: null,
}

export const ScrollbarContext = createContext<ScrollbarType>(initialContext)
const Provider = ScrollbarContext.Provider as React.Provider<ScrollbarType> &
    any
const ScrollbarProvider: React.FC = ({ children }) => {
    const scrollbar = useRef() as MutableRefObject<HTMLDivElement>
    return <Provider value={{ scrollbar: scrollbar }}>{children}</Provider>
}

export const useScrollbar = () => {
    const context = useContext(ScrollbarContext)
    if (!context) {
        throw new Error('Scrollbar not initialized')
    }
    return context
}

export default ScrollbarProvider
