import { styled } from 'src/styles/stitches.config';

export const StyledLetters = styled('div',{
    display: 'inline-block',
    overflow: 'hidden',
    lineHeight: 1,
    'span': {
        display: 'inline-block',
        position: 'relative',
        transform: 'translateY(105%)'
    }
});