import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    display: 'flex',

    '@mdDown': {
        flexDirection: 'column',
        padding: '20px',
        gap: '20px',
    },
    '@md': {
        gap: `calc(2 * $colWidth)`,
    },
})

export const TitleWrapper = styled('div', {
    '@md': {
        paddingLeft: '40px',
    },
})

export const FirstColumn = styled('div', {
    '@md': {
        width: 'calc(4 * $colWidth)',
        paddingLeft: '20px',
    },
})

export const SecondColumn = styled('div', {
    '@md': {
        width: 'calc(6 * $colWidth)',
        paddingTop: '10rem',
    },
})

export const ImageWrapper = styled('div', {
    width: 'calc(5 * $colWidth)',
    '@mdDown': {
        display: 'none',
    },
})
