import { breakpoints, styled, theme } from 'src/styles/stitches.config'
import { fluidSize } from 'src/styles/styleUtils'

const Text = styled('div', {
    fontFamily: '$primary',
    fontSize: '1rem',
    fontWeight: '$normal',
    letterSpacing: -0.5,
    variants: {
        color: Object.fromEntries(
            Object.keys(theme.colors).map((colorKey) => [
                colorKey,
                { color: `$${colorKey}` },
            ])
        ),
        align: {
            center: { textAlign: 'center' },
            right: { textAlign: 'right' },
            left: { textAlign: 'left' },
            justify: { textAlign: 'justify' },
        },
        textTransform: {
            uppercase: {
                textTransform: 'uppercase',
            },
            lowercase: {
                textTransform: 'lowercase',
            },
        },
        textVariant: {
            heading1: {
                lineHeight: 0.85,
                fontSize: '48px',
                '@sm': {
                    fontSize: fluidSize(
                        48,
                        96,
                        breakpoints.sm,
                        breakpoints.xxl
                    ),
                },
                '@xxl': {
                    fontSize: '96px',
                },
            },
            heading2: {
                fontSize: '6rem',
                fontWeight: '$bold',
            },
            heading3: {
                fontWeight: '$bold',
                fontSize: '34px',
                '@sm': {
                    fontSize: fluidSize(
                        34,
                        83,
                        breakpoints.sm,
                        breakpoints.xxl
                    ),
                },
                '@xxl': {
                    fontSize: '83px',
                },
            },
            heading4: {
                fontSize: '3.22rem',
                fontWeight: '$bold',
            },
            subheading4: {
                fontWeight: '$bold',
                fontSize: '17px',
                '@sm': {
                    fontSize: fluidSize(
                        17,
                        48,
                        breakpoints.sm,
                        breakpoints.xxl
                    ),
                },
                '@xxl': {
                    fontSize: '48px',
                },
            },
            title: {
                fontSize: '24px',
                '@sm': {
                    fontSize: fluidSize(
                        24,
                        40,
                        breakpoints.sm,
                        breakpoints.xxl
                    ),
                },
                '@xxl': {
                    fontSize: '40px',
                },
            },
            titleSmall: {
                fontSize: '1.5rem',
                fontWeight: '$bold',
            },
            base: {
                fontFamily: '$secondary',
                lineHeight: '1.2',
                fontSize: '18px',
                '@sm': {
                    fontSize: fluidSize(
                        18,
                        26,
                        breakpoints.sm,
                        breakpoints.xxl
                    ),
                },
                '@xxl': {
                    fontSize: '26px',
                },
            },

            base2: {
                fontFamily: '$secondary',
                lineHeight: '1.2',
                fontSize: '20px',
                '@sm': {
                    fontSize: fluidSize(
                        20,
                        32,
                        breakpoints.sm,
                        breakpoints.xxl
                    ),
                },
                '@xxl': {
                    fontSize: '32px',
                },
            },
            strongBase: {
                fontFamily: '$secondary',
                fontSize: '1.25rem',
                lineHeight: '1.2',
                fontWeight: '$bold',
            },
            strongSmall: {
                fontFamily: '$secondary',
                fontSize: '1rem',
                lineHeight: '1.2',
                fontWeight: '$medium',
            },
            subtitle: {
                fontSize: '16px',
                lineHeight: 1.2,
                '@sm': {
                    fontSize: fluidSize(
                        16,
                        27,
                        breakpoints.sm,
                        breakpoints.xxl
                    ),
                },
                '@xxl': {
                    fontSize: '27px',
                },
            },
            small: {
                fontFamily: '$secondary',
                fontSize: '0.9rem',
                lineHeight: '1.3',
            },
            small2: {
                fontFamily: '$secondary',
                fontSize: '1.15rem',
                lineHeight: '1.3',
                '@smDown': {
                    fontSize: '1rem',
                },
            },
            menu: {
                fontSize: '2rem',
                fontWeight: '$medium',
            },
        },
    },
})

export default Text
