import { styled } from 'src/styles/stitches.config'

export const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '$md',
    '@md': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: `calc(3 * $colWidth)`,
    },
})

export const TitleWrapper = styled('div', {
    '@md': {
        paddingLeft: '40px',
    },
})

export const Image = styled('div', {
    '@mdDown': {
        maxWidth: '50vw',
    },
})

export const Column = styled('div', {
    '& img': {
        marginBottom: '$md',
        maxWidth: '80%',
    },
    '@md': {
        width: '50%',

        '& img': {
            marginBottom: '$md',
            maxWidth: 'calc(7 * $colWidth)',
        },
    },
})
